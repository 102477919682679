import { createSlice } from "@reduxjs/toolkit";

const userDetails=localStorage.getItem('userInformation') !== null ? 
JSON.parse(localStorage.getItem('userInformation')) : null

const authSlice = createSlice({
    name:"auth",
    initialState:{
        userInformation:userDetails,
        notification:null,
        popup:{
            loginPopup:false,
            joinForFreePopup:false,
            showOtp:false,
            joinForFreeOtp:false
        }
    },
    reducers:{
        setCredentials:(state, action)=>{
            state.userInformation=action.payload
            localStorage.setItem('userInformation', 
            JSON.stringify(state.userInformation))
        },
        addNotification:(state, action)=>{
            state.notification=action.payload
        },
        logOut:(state)=>{
            state.userInformation=null
        },

        openLoginPopup:(state)=>{
            state.popup.loginPopup=true
        },
        closeLoginPopup:(state)=>{
            state.popup.loginPopup=false
        },
        openJoinForFreePopup:(state)=>{
            state.popup.joinForFreePopup=true
        },
        closeJoinForFreePopup:(state)=>{
            state.popup.joinForFreePopup=false
        },
        closeShowOtp:(state)=>{
            state.popup.showOtp=false
        },
        openShowOtp:(state)=>{
            state.popup.showOtp=true
        },
        closeJoinForFreeOtp:(state)=>{
            state.popup.joinForFreeOtp=false
        },
        openJoinForFreeOtp:(state)=>{
            state.popup.joinForFreeOtp=true
        },
    }
})

export const {setCredentials, logOut, openJoinForFreePopup, openLoginPopup,
closeJoinForFreePopup, closeLoginPopup, closeShowOtp, openShowOtp,
openJoinForFreeOtp, closeJoinForFreeOtp,addNotification}=authSlice.actions

export default authSlice.reducer

export const userInformation=(state)=>state.auth.userInformation
export const loginPopup=(state)=>state.auth.popup.loginPopup
export const joinForFreePopup=(state)=>state.auth.popup.joinForFreePopup
export const showOtp=(state)=>state.auth.popup.showOtp
export const showJoinForFreeOtp=(state)=>state.auth.popup.joinForFreeOtp
export const getNotification=(state)=>state.auth.notification
