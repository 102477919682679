import { createSlice } from "@reduxjs/toolkit";

const activeAdmin= sessionStorage.getItem('activeAdmin') !== null ? 
JSON.parse(sessionStorage.getItem('activeAdmin')) : null

const initialState={
    adminList:[],
    activeAdmin:activeAdmin,
    adminInformation:null,
    popup:{
     addAdminPopup:false,
     addManageAdminPopup:false,
     addRemoveAdminPopup:false,
     addVerifiedAdminPopup:false,
    },
    form:{
     adminForm:{
         mobile_number:"",
         first_name:"",
         last_name:"",
         gender:"",
         email_id:"",
         primary_role:""
     },
     addAdminComponent:1,
     manageAdminForm:{
         mobile_number:"",
         first_name:"",
         last_name:"",
         gender:"",
         email_id:"",
         Admin_id:"",
         primary_role:""
     },
     addManageAdminComponent:1,
    },
    adminAnalyticInformation:null,
    adminActivity:null
 }

const adminSlice=createSlice({
    name:'admin',
    initialState,
    reducers:{
        // admin information reducer
        addAdminList:(state, action)=>{
            state.adminList=action.payload
        },
        addAdminInformation:(state, action)=>{
            state.adminInformation=action.payload
            const adminObject={
                mobile_number:action.payload.mobile_number,
                first_name:action.payload.first_name,
                last_name:action.payload.last_name,
                gender:action.payload.gender,
                email_id:action.payload.email_id,
                admin_id:action.payload.id,
                primary_role:action.payload.primary_role
            }
            state.form.manageAdminForm=adminObject
        },
        addNewAdminList:(state, action)=>{
            state.adminList.push(action.payload)
        },
        updateAdminInformation:(state, action)=>{
            state.adminInformation=action.payload
            const adminObject={
                mobile_number:action.payload.mobile_number,
                first_name:action.payload.first_name,
                last_name:action.payload.last_name,
                gender:action.payload.gender,
                email_id:action.payload.email_id,
                admin_id:action.payload.id,
                primary_role:action.payload.primary_role
            }
            state.form.manageAdminForm=adminObject
            const newAdminList=state.adminList.map(item=>{
                if(item.id===action.payload.id){
                    const updateAdmin={
                        id:action.payload.id,
                        first_name:action.payload.first_name,
                        last_name:action.payload.last_name,
                        leave_request:action.payload.leave_request,
                        profile_picture:action.payload.profile_picture,
                    }
                    return updateAdmin
                }
                return item
            })
            state.adminList=newAdminList
        },
        addActiveAdmin:(state, action)=>{
            state.activeAdmin=action.payload
            sessionStorage.setItem('activeAdmin', 
            JSON.stringify(state.activeAdmin))
        },
        deleteAdminInformation:(state, action)=>{
            const newAdminList=state.adminList.filter(item=>item.id!==action.payload.id)
            state.adminList=newAdminList
        },
        addAdminAnalyticInformation:(state, action)=>{
            state.adminAnalyticInformation=action.payload
        },
        addAdminActivities:(state, action)=>{
            state.adminActivity=action.payload
        },

        // popup reducer
        openAddAdminPopup:(state)=>{
            state.popup.addAdminPopup=true
        },
        closeAddAdminPopup:(state)=>{
            state.popup.addAdminPopup=false
        },
        openAddManageAdminPopup:(state)=>{
            state.popup.addManageAdminPopup=true
        },
        closeAddManageAdminPopup:(state)=>{
            state.popup.addManageAdminPopup=false
        },
        openAddRemoveAdminPopup:(state)=>{
            state.popup.addRemoveAdminPopup=true
        },
        closeAddRemoveAdminPopup:(state)=>{
            state.popup.addRemoveAdminPopup=false
        },
        openAddVerifiedAdminPopup:(state)=>{
            state.popup.addVerifiedAdminPopup=true
        },
        closeAddVerifiedAdminPopup:(state)=>{
            state.popup.addVerifiedAdminPopup=false
        },

        // form reducers
        addAdminComponent:(state, action)=>{
            state.form.addAdminComponent=action.payload
        },
        addManageAdminComponent:(state, action)=>{
            state.form.addManageAdminComponent=action.payload
        },
        addAdminForm:(state, action)=>{
            state.form.adminForm=action.payload
        },
        addManageAdminForm:(state, action)=>{
            state.form.manageAdminForm=action.payload
        },
        addAdminMobileNumber:(state, action)=>{
            state.form.adminForm.mobile_number=action.payload
        },
    },
  
})
export const {addActiveAdmin, addAdminActivities, addAdminAnalyticInformation, addAdminComponent,
addAdminForm, addAdminInformation, addAdminList, addAdminMobileNumber, addManageAdminComponent,
addManageAdminForm, addNewAdminList, openAddAdminPopup, openAddManageAdminPopup,
openAddRemoveAdminPopup, openAddVerifiedAdminPopup, closeAddManageAdminPopup,
closeAddRemoveAdminPopup, closeAddVerifiedAdminPopup, updateAdminInformation,
deleteAdminInformation, closeAddAdminPopup}=adminSlice.actions

// admin information state
export const getAdminList=(state)=>state.admin.adminList
export const getActiveAdmin=(state)=>state.admin.activeAdmin
export const getAdminInformation=(state)=>state.admin.adminInformation
export const getAdminAnalyticInformation=(state)=>state.admin.adminAnalyticInformation
export const getAdminActivityInformation=(state)=>state.admin.adminActivity

// admin popup  state
export const getAddAdminPopup=(state)=>state.admin.popup.addAdminPopup
export const getAddManageAdminPopup=(state)=>state.admin.popup.addManageAdminPopup
export const getAddRemoveAdminPopup=(state)=>state.admin.popup.addRemoveAdminPopup
export const getAddVerifiedAdminPopup=(state)=>state.admin.popup.addVerifiedAdminPopup

// admin form state
export const getAdminForm=(state)=>state.admin.form.adminForm
export const getManageAdminForm=(state)=>state.admin.form.manageAdminForm
export const getAddAdminComponent=(state)=>state.admin.form.addAdminComponent
export const getAddManageAdminComponent=(state)=>state.admin.form.addManageAdminComponent

export default adminSlice.reducer