import { createSlice } from "@reduxjs/toolkit";

const activeHelpingStaff= sessionStorage.getItem('activeHelpingStaff') !== null ? 
JSON.parse(sessionStorage.getItem('activeHelpingStaff')) : null

const initialState={
    helpingStaffList:[],
    activeHelpingStaff:activeHelpingStaff,
    helpingStaffInformation:null,
    popup:{
     addHelpingStaffPopup:false,
     addManageHelpingStaffPopup:false,
     addRemoveHelpingStaffPopup:false,
     addVerifiedHelpingStaffPopup:false,
    },
    form:{
     helpingStaffForm:{
         mobile_number:"",
         first_name:"",
         last_name:"",
         gender:"",
         email_id:"",
         primary_role:""
     },
     addHelpingStaffComponent:1,
     manageHelpingStaffForm:{
         mobile_number:"",
         first_name:"",
         last_name:"",
         gender:"",
         email_id:"",
         Admin_id:"",
         primary_role:""
     },
     addManageHelpingStaffComponent:1,
    },
 }

const helpingStaffSlice=createSlice({
    name:"helpingStaff",
    initialState,
    reducers:{
        // helping staff information
        addHelpingStaffList:(state, action)=>{
            state.helpingStaffList=action.payload
        },
        addHelpingStaffInformation:(state, action)=>{
            state.helpingStaffInformation=action.payload
            const helpingStaffObject={
                mobile_number:action.payload.mobile_number,
                first_name:action.payload.first_name,
                last_name:action.payload.last_name,
                gender:action.payload.gender,
                email_id:action.payload.email_id,
                helping_staff_id:action.payload.id,
                primary_role:action.payload.primary_role
            }
            state.form.manageHelpingStaffForm=helpingStaffObject
        },
        addNewHelpingStaffList:(state, action)=>{
            state.helpingStaffList.push(action.payload)
        },
        updateHelpingStaffInformation:(state, action)=>{
            state.helpingStaffInformation=action.payload
            const helpingStaffObject={
                mobile_number:action.payload.mobile_number,
                first_name:action.payload.first_name,
                last_name:action.payload.last_name,
                gender:action.payload.gender,
                email_id:action.payload.email_id,
                helping_staff_id:action.payload.id,
                primary_role:action.payload.primary_role
            }
            state.form.manageHelpingStaffForm=helpingStaffObject
            const newHelpingStaffList=state.helpingStaffList.map(item=>{
                if(item.id===action.payload.id){
                    const updateHelpingStaff={
                        id:action.payload.id,
                        first_name:action.payload.first_name,
                        last_name:action.payload.last_name,
                        leave_request:action.payload.leave_request,
                        profile_picture:action.payload.profile_picture,
                    }
                    return updateHelpingStaff
                }
                return item
            })
            state.helpingStaffList=newHelpingStaffList
        },
        addActiveHelpingStaff:(state, action)=>{
            state.activeHelpingStaff=action.payload
            sessionStorage.setItem('activeHelpingStaff', 
            JSON.stringify(state.activeHelpingStaff))           
        },
        deleteHelpingStaffInformation:(state, action)=>{
            const newHelpingStaff=state.helpingStaffList.filter(item=>item.id!==action.payload.id)
            state.helpingStaffList=newHelpingStaff
        },

        //helpingStaff popup
        openAddHelpingStaffPopup:(state)=>{
            state.popup.addHelpingStaffPopup=true
        },
        closeAddHelpingStaffPopup:(state)=>{
            state.popup.addHelpingStaffPopup=false
        },
        openAddManageHelpingStaffPopup:(state)=>{
            state.popup.addManageHelpingStaffPopup=true
        },
        closeAddManageHelpingStaffPopup:(state)=>{
            state.popup.addManageHelpingStaffPopup=false
        },
        openAddRemoveHelpingStaffPopup:(state)=>{
            state.popup.addRemoveHelpingStaffPopup=true
        },
        closeAddRemoveHelpingStaffPopup:(state)=>{
            state.popup.addRemoveHelpingStaffPopup=false
        },
        openAddVerifiedHelpingStaffPopup:(state)=>{
            state.popup.addVerifiedHelpingStaffPopup=true
        },
        closeAddVerifiedHelpingStaffPopup:(state)=>{
            state.popup.addVerifiedHelpingStaffPopup=false
        },

        // form reducers
        addHelpingStaffComponent:(state, action)=>{
            state.form.addHelpingStaffComponent=action.payload
        },
        addManageHelpingStaffComponent:(state, action)=>{
            state.form.addManageHelpingStaffComponent=action.payload
        },
        addHelpingStaffForm:(state, action)=>{
            state.form.helpingStaffForm=action.payload
        },
        addManageHelpingStaffForm:(state, action)=>{
            state.form.manageHelpingStaffForm=action.payload
        },
        addHelpingStaffMobileNumber:(state, action)=>{
            state.form.helpingStaffForm.mobile_number=action.payload
        },
    },
})

export const {addActiveHelpingStaff, addHelpingStaffInformation, addHelpingStaffList,
addNewHelpingStaffList, updateHelpingStaffInformation,
deleteHelpingStaffInformation, openAddHelpingStaffPopup, openAddManageHelpingStaffPopup,
openAddRemoveHelpingStaffPopup, openAddVerifiedHelpingStaffPopup,
closeAddHelpingStaffPopup, closeAddManageHelpingStaffPopup, closeAddRemoveHelpingStaffPopup,
closeAddVerifiedHelpingStaffPopup, addHelpingStaffComponent, addHelpingStaffForm,
addHelpingStaffMobileNumber, addManageHelpingStaffComponent, addManageHelpingStaffForm}=helpingStaffSlice.actions

// helping staff state
export const getHelpingStaffList=(state)=>state.helpingStaff.helpingStaffList
export const getActiveHelpingStaff=(state)=>state.helpingStaff.activeHelpingStaff
export const getHelpingStaffInformation=(state)=>state.helpingStaff.helpingStaffInformation

// helping staff popup state
export const getAddHelpingStaffPopup=(state)=>state.helpingStaff.popup.addHelpingStaffPopup
export const getAddManageHelpingStaffPopup=(state)=>state.helpingStaff.popup.addManageHelpingStaffPopup
export const getAddRemoveHelpingStaffPopup=(state)=>state.helpingStaff.popup.addRemoveHelpingStaffPopup
export const getAddVerifiedHelpingStaffPopup=(state)=>state.helpingStaff.popup.addVerifiedHelpingStaffPopup

// helpingStaff form state
export const getHelpingStaffForm=(state)=>state.helpingStaff.form.helpingStaffForm
export const getManageHelpingStaffForm=(state)=>state.helpingStaff.form.manageHelpingStaffForm
export const getAddHelpingStaffComponent=(state)=>state.helpingStaff.form.addHelpingStaffComponent
export const getAddManageHelpingStaffComponent=(state)=>state.helpingStaff.form.addManageHelpingStaffComponent

export default  helpingStaffSlice.reducer