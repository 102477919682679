import { createSlice } from "@reduxjs/toolkit";

const classList= sessionStorage.getItem('classList') !== null ? 
JSON.parse(sessionStorage.getItem('classList')) : []
const activeClass= sessionStorage.getItem('activeClass') !== null ? 
JSON.parse(sessionStorage.getItem('activeClass')) : null

const classSlice = createSlice({
    name:"sidebar",
    initialState:{
        classList:classList,
        activeClass:activeClass,
        classInformation:null,
        kidList:[],
        studentInformation:null,
        kidInformation:null,
        kidTransaction:null,
        activeKidId:null,
        popup:{
            addNewClassPopup:false,
            addRemoveClassPopup:false,
            changeTeacherPopup:false,
            addManageClassPopup:false,
            addEnrollChildPopup:false,
            addUploadInBulkPopup:false,
            addVerifiedKidProfile:false,
            addVerifiedKidDocument:false,
        },
        form:{
            classForm:{
                class_name:"",
                teacher_in_charge:"",
                program_name:"",
            },
            changeTeacherForm:{
                class_id:"",
                teacher_id:"",
            },
            manageClassForm:{
                class_name:"",
                teacher_id:"",
                program_name:"",
                class_id:"",
            },
            enrollChildForm:{
                first_name:"",
                last_name:"",
                gender:"",
                date_of_birth:""
            },
            studentListForm:[],
            addUploadBulkInChildForm:null,
            manageClassComponent:1,
            enrollChildComponent:1,
            uploadInBulkComponent:1,
            kidForm:{
                first_name:"",
                last_name:"",
                primary_mobile_number:"",
                secondary_mobile_number:"",
                gender:"",
                date_of_birth:"",
            }
        },
    },
    reducers:{
        // class information reducer
        addClassList:(state, action)=>{
            state.classList=action.payload
            sessionStorage.setItem('classList', 
            JSON.stringify(state.classList))
        },
        addActiveClass:(state, action)=>{
            state.activeClass=action.payload
            sessionStorage.setItem('activeClass', 
            JSON.stringify(state.activeClass))
        },
        updateClassList:(state, action)=>{
            state.classList.push(action.payload)
        },
        addClassInformation:(state, action)=>{
            state.classInformation=action.payload
            const classObject={
                class_id:action.payload?.id,
                teacher_id:action.payload?.class_teacher?.id
            }
            
            const manageClassObject={
                class_name:action.payload?.class_name,
                teacher_id:action.payload?.class_teacher?.id,
                program_name:action.payload?.program,
                class_id:action.payload?.id,
            }
            state.form.changeTeacherForm=classObject
            state.form.manageClassForm=manageClassObject
        },
        addKidList:(state, action)=>{
            state.kidList=action.payload
        },
        addStudentInformation:(state, action)=>{
            state.studentInformation=action.payload
        },
        addKidInformation:(state, action)=>{
            state.kidInformation=action.payload
        },
        addKidTransaction:(state, action)=>{
            state.kidTransaction=action.payload
        },
        addActiveKid:(state, action)=>{
            state.activeKidId=action.payload
        },

        // class popup reducer
        openAddNewClassPopup:(state)=>{
            state.popup.addNewClassPopup=true
        },
        closeAddNewClassPopup:(state)=>{
            state.popup.addNewClassPopup=false
        },
        openRemoveClassPopup:(state)=>{
            state.popup.addRemoveClassPopup=true
        },
        closeRemoveClassPopup:(state)=>{
            state.popup.addRemoveClassPopup=false
        },
        openChangeTeacherPopup:(state)=>{
            state.popup.changeTeacherPopup=true
        },
        closeChangeTeacherPopup:(state)=>{
            state.popup.changeTeacherPopup=false
        },
        openAddManageClassPopup:(state)=>{
            state.popup.addManageClassPopup=true
        },
        closeAddManageClassPopup:(state)=>{
            state.popup.addManageClassPopup=false
        },
        openAddEnrollChildPopup:(state)=>{
            state.popup.addEnrollChildPopup=true
        },
        closeAddEnrollChildPopup:(state)=>{
            state.popup.addEnrollChildPopup=false
        },
        openAddUploadInBulkPopup:(state)=>{
            state.popup.addUploadInBulkPopup=true
        },
        closeAddUploadInBulkPopup:(state)=>{
            state.popup.addUploadInBulkPopup=false
        },
        openAddVerifiedDocumentPopup:(state)=>{
            state.popup.addVerifiedKidDocument=true
        },
        closeAddVerifiedDocumentPopup:(state)=>{
            state.popup.addVerifiedKidDocument=false
        },
        openAddVerifiedProfilePopup:(state)=>{
            state.popup.addVerifiedKidProfile=true
        },
        closeAddVerifiedProfilePopup:(state)=>{
            state.popup.addVerifiedKidProfile=false
        },

        // class form reducer
        addClassForm:(state, action)=>{
            state.form.classForm=action.payload
        },
        addChangeTeacherForm:(state, action)=>{
            state.form.changeTeacherForm=action.payload
        },
        addManageClassForm:(state, action)=>{
            state.form.manageClassForm=action.payload
        },
        addManageClassComponent:(state, action)=>{
            state.form.manageClassComponent=action.payload
        },
        addEnrollChildComponent:(state, action)=>{
            state.form.enrollChildComponent=action.payload
        },
        addEnrollChildForm:(state, action)=>{
            state.form.enrollChildForm=action.payload
        },
        addUploadInBulkComponent:(state, action)=>{
            state.form.uploadInBulkComponent=action.payload
        },
        addStudentListForm:(state, action)=>{
            state.form.studentListForm=action.payload
        },
        addUploadBulkInChildForm:(state, action)=>{
            state.form.addUploadBulkInChildForm=action.payload
        },
        addKidForm:(state, action)=>{
            state.form.kidForm=action.payload
        }
    }
})

export const {addClassList, addActiveClass, closeAddNewClassPopup,
openAddNewClassPopup, addClassForm, updateClassList,
addClassInformation, openAddManageClassPopup, openChangeTeacherPopup,
openRemoveClassPopup, closeAddManageClassPopup, closeChangeTeacherPopup,
closeRemoveClassPopup, addChangeTeacherForm, 
addManageClassComponent, addManageClassForm, openAddEnrollChildPopup,
closeAddEnrollChildPopup, openAddUploadInBulkPopup, openAddVerifiedDocumentPopup,
openAddVerifiedProfilePopup, closeAddUploadInBulkPopup, closeAddVerifiedDocumentPopup,
closeAddVerifiedProfilePopup, addKidList, addStudentInformation,
addEnrollChildComponent, addEnrollChildForm, addKidInformation,
addKidTransaction, addUploadInBulkComponent, addStudentListForm,
addUploadBulkInChildForm, addActiveKid, addKidForm}=classSlice.actions

// class information state
export const getClassList=(state)=>state.class.classList
export const getActiveClass=(state)=>state.class.activeClass
export const getClassInformation=(state)=>state.class.classInformation
export const getKidList=(state)=>state.class.kidList
export const getStudentInformation=(state)=>state.class.studentInformation
export const getKidInformation=(state)=>state.class.kidInformation
export const getKidTransaction=(state)=>state.class.kidTransaction
export const getActiveKidId=(state)=>state.class.activeKidId

// class popup  state
export const getAddNewClassPopup=(state)=>state.class.popup.addNewClassPopup
export const getAddRemoveClassPopup=(state)=>state.class.popup.addRemoveClassPopup
export const getChangeClassTeacherPopup=(state)=>state.class.popup.changeTeacherPopup
export const getAddManageClassPopup=(state)=>state.class.popup.addManageClassPopup
export const getAddEnrollChildPopup=(state)=>state.class.popup.addEnrollChildPopup
export const getAddUploadInBulkPopup=(state)=>state.class.popup.addUploadInBulkPopup
export const getAddVerifiedKidProfilePopup=(state)=>state.class.popup.addVerifiedKidProfile
export const getAddVerifiedKidDocumentPopup=(state)=>state.class.popup.addVerifiedKidDocument

// class form state
export const getClassForm=(state)=>state.class.form.classForm
export const getChangeTeacherForm=(state)=>state.class.form.changeTeacherForm
export const getManageClassForm=(state)=>state.class.form.manageClassForm
export const getManageClassComponent=(state)=>state.class.form.manageClassComponent
export const getAddEnrollChildComponent=(state)=>state.class.form.enrollChildComponent
export const getEnrollChildForm=(state)=>state.class.form.enrollChildForm
export const getUploadInBulkComponent=(state)=>state.class.form.uploadInBulkComponent
export const getStudentListForm=(state)=>state.class.form.studentListForm
export const getUploadInBulkForm=(state)=>state.class.form.addUploadBulkInChildForm
export const getKidForm=(state)=>state.class.form.kidForm

export default classSlice.reducer

