import { createSlice } from '@reduxjs/toolkit'

export const popup=createSlice({
    name:"popup",
    initialState: {
        created:false,
        deleted:false,
        updated:false
    },
    reducers:{
        openCreated:(state)=>{
            state.created=true
        },
        closeCreated:(state)=>{
            state.created=false
        },
        openDeleted:(state)=>{
            state.deleted=true
        },
        closeDeleted:(state)=>{
            state.deleted=false
        },
        openUpdated:(state)=>{
            state.updated=true
        },
        closeUpdated:(state)=>{
            state.updated=false
        },
    }
})

export const {openCreated, openDeleted, openUpdated,
closeCreated, closeDeleted, closeUpdated}=popup.actions

// popup state
export const getCreated=(state)=>state.popup.created
export const getUpdated=(state)=>state.popup.updated
export const getDeleted=(state)=>state.popup.deleted

export default popup.reducer