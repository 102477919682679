// mues tech server
// export const mainUrl="http://13.235.67.28:8000/" // development server
// export const mainUrl="http://192.168.1.47:8002/" // local server
// export const mainUrl="http://43.204.29.73:8000/" // testing server
export const mainUrl="http://3.110.119.136:8000/" // production server

// mues aus server
// export const mainUrl="http://13.54.68.36:8000/" // development server

// curriculum server
// export const curriculumUrl="http://15.206.151.172:8000/" // development server
// export const curriculumUrl="http://192.168.1.47:8006/" // local server
// export const curriculumUrl="http://52.66.65.233:8000/" // testing server
export const curriculumUrl="http://13.234.202.119:8000/" // production server

// curriculum aus server
// export const curriculumUrl="http://35.154.250.38:8000/" // development server


// financial server
// export const financialUrl="http://65.0.199.136:8000/" // development server
// export const financialUrl="http://192.168.1.47:5000/" // local server
// export const financialUrl="http://3.109.108.118:8000/" // testing server
export const financialUrl="http://43.205.118.39:8000/" // production server

// chat server 
// export const chatServer="ws://65.0.45.65:8000/principal-chat/" // development server
// export const chatServer="ws://192.168.1.47:8007/principal-chat/" // local server
// export const chatServer="ws://13.201.226.171:8000/principal-chat/" // testing server
export const chatServer="ws://65.1.64.18:8000/principal-chat/" // production server

