import { createSlice } from "@reduxjs/toolkit";

const activeTeacher= sessionStorage.getItem('activeTeacher') !== null ? 
JSON.parse(sessionStorage.getItem('activeTeacher')) : null
  
const initialState={
   teacherList:[],
   activeTeacher:activeTeacher,
   teacherInformation:null,
   popup:{
    addTeacherPopup:false,
    addManageTeacherPopup:false,
    addRemoveTeacherPopup:false,
    addVerifiedTeacherPopup:false,
    addMakeAdminPopup:false,
    addDeleteTeacherPopup:false,
   },
   form:{
    teacherForm:{
        mobile_number:"",
        first_name:"",
        last_name:"",
        gender:"",
        email_id:"",
    },
    addTeacherComponent:1,
    manageTeacherForm:{
        mobile_number:"",
        first_name:"",
        last_name:"",
        gender:"",
        email_id:"",
        teacher_id:""
    },
    addManageTeacherComponent:1,
   },
   teacherAnalyticInformation:null,
   teacherActivity:null
}

const teacherSlice=createSlice({
    name:"teacher",
    initialState,
    reducers:{
        // teacher information reducer
        addTeacherList:(state, action)=>{
            state.teacherList=action.payload
        },
        addTeacherInformation:(state, action)=>{
            state.teacherInformation=action.payload
            const teacherObject={
                mobile_number:action.payload?.mobile_number,
                first_name:action.payload?.first_name,
                last_name:action.payload?.last_name,
                gender:action.payload?.gender,
                email_id:action.payload?.email_address,
                teacher_id:action.payload?.id
            }
            state.form.manageTeacherForm=teacherObject
        },
        addNewTeacherList:(state, action)=>{
            state.teacherList.push(action.payload)
        },
        updateTeacherInformation:(state, action)=>{
            state.teacherInformation=action.payload
            const teacherObject={
                mobile_number:action.payload.mobile_number,
                first_name:action.payload.first_name,
                last_name:action.payload.last_name,
                gender:action.payload.gender,
                email_id:action.payload.email_address,
                teacher_id:action.payload.id
            }
            state.form.manageTeacherForm=teacherObject
            const newTeacherList=state.teacherList.map(item=>{
                if(item.id===action.payload.id){
                    const updateTeacher={
                        id:action.payload.id,
                        first_name:action.payload.first_name,
                        last_name:action.payload.last_name,
                        leave_request:action.payload.leave_request,
                        profile_picture:action.payload.profile_picture,
                    }
                    return updateTeacher
                }
                return item
            })
            state.teacherList=newTeacherList
        },
        addActiveTeacher:(state, action)=>{
            state.activeTeacher=action.payload
            sessionStorage.setItem('activeTeacher', 
            JSON.stringify(state.activeTeacher))
        },
        deleteTeacherInformation:(state, action)=>{
            const newTeacherList=state.teacherList.filter(item=>item.id!==action.payload.id)
            state.teacherList=newTeacherList
        },
        addTeacherAnalyticInformation:(state, action)=>{
            state.teacherAnalyticInformation=action.payload
        },
        addTeacherActivities:(state, action)=>{
            state.teacherActivity=action.payload
        },

        // popup reducer
        openAddTeacherPopup:(state)=>{
            state.popup.addTeacherPopup=true
        },
        closeAddTeacherPopup:(state)=>{
            state.popup.addTeacherPopup=false
        },
        openAddManageTeacherPopup:(state)=>{
            state.popup.addManageTeacherPopup=true
        },
        closeAddManageTeacherPopup:(state)=>{
            state.popup.addManageTeacherPopup=false
        },
        openAddRemoveTeacherPopup:(state)=>{
            state.popup.addRemoveTeacherPopup=true
        },
        closeAddRemoveTeacherPopup:(state)=>{
            state.popup.addRemoveTeacherPopup=false
        },
        openAddVerifiedTeacherPopup:(state)=>{
            state.popup.addVerifiedTeacherPopup=true
        },
        closeAddVerifiedTeacherPopup:(state)=>{
            state.popup.addVerifiedTeacherPopup=false
        },
        openAddMakeAdminPopup:(state)=>{
            state.popup.addMakeAdminPopup=true
        },
        closeAddMakeAdminPopup:(state)=>{
            state.popup.addMakeAdminPopup=false
        },
        openAddDeleteTeacherPopup:(state)=>{
            state.popup.addDeleteTeacherPopup=true
        },
        closeAddDeleteTeacherPopup:(state)=>{
            state.popup.addDeleteTeacherPopup=false
        },

        // form reducers
        addTeacherComponent:(state, action)=>{
            state.form.addTeacherComponent=action.payload
        },
        addManageTeacherComponent:(state, action)=>{
            state.form.addManageTeacherComponent=action.payload
        },
        addTeacherForm:(state, action)=>{
            state.form.teacherForm=action.payload
        },
        addManageTeacherForm:(state, action)=>{
            state.form.manageTeacherForm=action.payload
        },
        addTeacherMobileNumber:(state, action)=>{
            state.form.teacherForm.mobile_number=action.payload
        },
    }
})

export const {addTeacherList, addActiveTeacher, closeAddTeacherPopup,
openAddTeacherPopup, openAddManageTeacherPopup,
closeAddManageTeacherPopup, closeAddRemoveTeacherPopup, 
openAddRemoveTeacherPopup, closeAddVerifiedTeacherPopup, 
openAddVerifiedTeacherPopup, addManageTeacherComponent, 
addTeacherComponent, addManageTeacherForm, addTeacherForm,
addTeacherMobileNumber, addNewTeacherList, 
addTeacherInformation, updateTeacherInformation, 
deleteTeacherInformation, addTeacherAnalyticInformation, 
addTeacherActivities, openAddMakeAdminPopup, 
closeAddMakeAdminPopup, openAddDeleteTeacherPopup, closeAddDeleteTeacherPopup}=teacherSlice.actions

// teacher information state
export const getTeacherList=(state)=>state.teacher.teacherList
export const getActiveTeacher=(state)=>state.teacher.activeTeacher
export const getTeacherInformation=(state)=>state.teacher.teacherInformation
export const getTeacherAnalyticInformation=(state)=>state.teacher.teacherAnalyticInformation
export const getTeacherActivityInformation=(state)=>state.teacher.teacherActivity

// teacher popup  state
export const getAddTeacherPopup=(state)=>state.teacher.popup.addTeacherPopup
export const getAddManageTeacherPopup=(state)=>state.teacher.popup.addManageTeacherPopup
export const getAddRemoveTeacherPopup=(state)=>state.teacher.popup.addRemoveTeacherPopup
export const getAddVerifiedTeacherPopup=(state)=>state.teacher.popup.addVerifiedTeacherPopup
export const getAddMakeAdminPopup=(state)=>state.teacher.popup.addMakeAdminPopup
export const getAddDeleteTeacherPopup=(state)=>state.teacher.popup.addDeleteTeacherPopup

// teacher form state
export const getTeacherForm=(state)=>state.teacher.form.teacherForm
export const getManageTeacherForm=(state)=>state.teacher.form.manageTeacherForm
export const getAddTeacherComponent=(state)=>state.teacher.form.addTeacherComponent
export const getAddManageTeacherComponent=(state)=>state.teacher.form.addManageTeacherComponent

export default teacherSlice.reducer