import { createSlice } from "@reduxjs/toolkit";

const preschoolInformation= localStorage.getItem('preschoolInformation') !== null ? 
JSON.parse(localStorage.getItem('preschoolInformation')) : null

const yearList= sessionStorage.getItem('yearList') !== null ? 
JSON.parse(sessionStorage.getItem('yearList')) : []

const notification= sessionStorage.getItem('notification') !== null ? 
JSON.parse(sessionStorage.getItem('notification')) : null

const initialState={
    preschoolList:[],
    preschoolInformation:preschoolInformation,
    yearList:yearList,
    notification:notification,
    form:{
        preschoolForm:{
            preschool_name:"",
            address:"",
            city:"",
            state:"",
            pincode:"",
            email_id:"",
            preschool_director:""
        }
    }
}

const preschoolSlice=createSlice({
    name:'preschool',
    initialState,
    reducers:{
        addPreschoolList:(state, action)=>{
            state.preschoolList=action.payload
        },
        addPreschoolInformation:(state, action)=>{
            state.preschoolInformation=action.payload
            localStorage.setItem('preschoolInformation', 
            JSON.stringify(state.preschoolInformation))
        },
        addYearList:(state, action)=>{
            state.yearList=action.payload
            sessionStorage.setItem('yearList', 
            JSON.stringify(state.yearList))
        },
        addNotification:(state, action)=>{
            state.notification=action.payload
            sessionStorage.setItem('notification', 
            JSON.stringify(state.notification))
        },

        //preschool form reducer
        addPreschoolForm:(state,action)=>{
            state.form.preschoolForm=action.payload
        }

    }
})

export const {addPreschoolList, addPreschoolInformation, 
    addYearList, addPreschoolForm, addNotification}=preschoolSlice.actions

//preschool information state
export const getPreschoolList=(state)=>state.preschool.preschoolList
export const getPreschoolInformation=(state)=>state.preschool.preschoolInformation
export const getPreschoolYearList=(state)=>state.preschool.yearList
export const getPreschoolNotification=(state)=>state.preschool.notification

//preschool form state
export const getPreschoolForm=(state)=>state.preschool.form.preschoolForm

export default preschoolSlice.reducer