import { configureStore } from '@reduxjs/toolkit';

import principalSlice from './preschool/principalSlice';
import preschoolSlice from './preschool/preschool';

import { apiSlice } from './api/authApiSlice';
import { authFinancialSlice } from './api/authFinancialSlice';
import { authCurriculumApiSlice } from './api/authCurriculumApiSlice';
import {parentSliceApi} from './api/parentSliceApi';
import authReducer from './user/user';
import sidebarSlice from './sidebar/sidebar';
import classSlice from './class/class';
import teacherSlice from './Team/teacherSlice';
import adminSlice from './Team/adminSlice'
import helpingStaffSlice from './Team/helpingStaffSlice'
import popup from './popup/popup';
import elg from './curriculum/elg'
import annualPlanner from './curriculum/annualPlanner'
import planner from './curriculum/planner';
import tourSlice from './Tour/tourSlice';
import feesOverview from './finances/feesOverview';
import accountDelete from './accountDelete/accountDelete';
import boardcast from './boardcast/boardcast';
import event from './event/event';
import chat from './chat/chat';

export const store = configureStore({
    reducer:{
        [apiSlice.reducerPath]:apiSlice.reducer,
        [authFinancialSlice.reducerPath]:authFinancialSlice.reducer,
        [authCurriculumApiSlice.reducerPath]:authCurriculumApiSlice.reducer,
        [parentSliceApi.reducerPath]:parentSliceApi.reducer,
        auth:authReducer,
        principal:principalSlice,
        preschool:preschoolSlice,
        sidebar:sidebarSlice,
        class:classSlice,
        teacher:teacherSlice,
        popup:popup,
        admin:adminSlice,
        helpingStaff:helpingStaffSlice,
        elg:elg,
        annualPlanner:annualPlanner,
        planner:planner,
        tour:tourSlice,
        feesOverview:feesOverview,
        accountDelete:accountDelete,
        boardcast:boardcast,
        event:event,
        chat:chat,
    },
    middleware: getDefaultMiddleware=>
        getDefaultMiddleware().concat(apiSlice.middleware,
            authFinancialSlice.middleware, authCurriculumApiSlice.middleware,
            parentSliceApi.middleware),
    devTools:true
})