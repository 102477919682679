import { createSlice } from "@reduxjs/toolkit";

const tourSlice=createSlice({
    name:"tour",
    initialState:{
        homeTour:false,
        teacherTour:false,
        adminTour:false,
        helpingStaffTour:false,
        classTour:false,
        teacherTourIndex:0,
        adminTourIndex:0,
        helpingStaffTourIndex:0,
        classTourIndex:0,
        elgTour:false,
        elgTourIndex:0,
        annualPlannerTour:false,
        annualPlannerTourIndex:0,
        plannerTour:false,
        plannerTourIndex:0,
    },
    reducers:{
        openHomeTour:(state)=>{
            state.homeTour=true
        },
        closeHomeTour:(state)=>{
            state.homeTour=false
        },
        openTeacherTour:(state)=>{
            state.teacherTour=true
        },
        closeTeacherTour:(state)=>{
            state.teacherTour=false
        },
        openAdminTour:(state)=>{
            state.adminTour=true
        },
        closeAdminTour:(state)=>{
            state.adminTour=false
        },
        openHelpingStaffTour:(state)=>{
            state.helpingStaffTour=true
        },
        closeHelpingStaffTour:(state)=>{
            state.helpingStaffTour=false
        },
        openClassTour:(state)=>{
            state.classTour=true
        },
        closeClassTour:(state)=>{
            state.classTour=false
        },
        openElgTour:(state)=>{
            state.elgTour=true
        },
        closeElgTour:(state)=>{
            state.elgTour=false
        },
        openAnnualPlannerTour:(state)=>{
            state.annualPlannerTour=true
        },
        closeAnnualPlannerTour:(state)=>{
            state.annualPlannerTour=false
        },
        openPlannerTour:(state)=>{
            state.plannerTour=true
        },
        closePlannerTour:(state)=>{
            state.pannerTour=false
        },

        addTeacherTourIndex:(state, action)=>{
            state.teacherTourIndex=action.payload
        },
        addAdminTourIndex:(state, action)=>{
            state.adminTourIndex=action.payload
        },
        addHelpingStaffTourIndex:(state, action)=>{
            state.helpingStaffTourIndex=action.payload
        },
        addClassTourIndex:(state, action)=>{
            state.classTourIndex=action.payload
        },
        addElgTourIndex:(state, action)=>{
            state.elgTourIndex=action.payload
        },
        addAnnualPlannerTourIndex:(state, action)=>{
            state.annualPlannerTourIndex=action.payload
        },
        addPlannerTourIndex:(state, action)=>{
            state.plannerTourIndex=action.payload
        },
    }
})
export const {openHomeTour, closeHomeTour, openTeacherTour,
closeTeacherTour, addTeacherTourIndex, openAdminTour, closeAdminTour,
addAdminTourIndex, openHelpingStaffTour, closeHelpingStaffTour,
addHelpingStaffTourIndex, openClassTour, closeClassTour,
addClassTourIndex, openElgTour, closeElgTour, addElgTourIndex,
openAnnualPlannerTour, closeAnnualPlannerTour, addAnnualPlannerTourIndex,
openPlannerTour, closePlannerTour, addPlannerTourIndex}=tourSlice.actions

export const getHomeTour=(state)=>state.tour.homeTour
export const getTeacherTour=(state)=>state.tour.teacherTour
export const getTeacherTourIndex=(state)=>state.tour.teacherTourIndex
export const getAdminTour=(state)=>state.tour.adminTour
export const getAdminTourIndex=(state)=>state.tour.adminTourIndex
export const getHelpingStaffTour=(state)=>state.tour.helpingStaffTour
export const getHelpingStaffTourIndex=(state)=>state.tour.helpingStaffTourIndex
export const getClassTour=(state)=>state.tour.classTour
export const getClassTourIndex=(state)=>state.tour.classTourIndex
export const getElgTour=(state)=>state.tour.elgTour
export const getElgTourIndex=(state)=>state.tour.elgTourIndex
export const getAnnualPlannerTour=(state)=>state.tour.annualPlannerTour
export const getAnnualPlannerIndex=(state)=>state.tour.annualPlannerTourIndex
export const getPlannerTour=(state)=>state.tour.plannerTour
export const getPlannerIndex=(state)=>state.tour.plannerTourIndex

export default tourSlice.reducer