import { createSlice } from "@reduxjs/toolkit";

const principalInformation= localStorage.getItem('principalInformation') !== null ? 
JSON.parse(localStorage.getItem('principalInformation')) : null

const principalSlice=createSlice({
    name:"principal",
    initialState:{
        principalInformation:principalInformation,
    },
    reducers:{
        addPrincipalInformation:(state, action)=>{
            state.principalInformation=action.payload
            localStorage.setItem('principalInformation', 
            JSON.stringify(state.principalInformation))
        }
    }
})

export const {addPrincipalInformation}=principalSlice.actions

export default principalSlice.reducer;

export const getPrincipalInformation=(state)=>state.principal.principalInformation