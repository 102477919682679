import { createSlice } from "@reduxjs/toolkit";
import Moment from "moment";

const event = createSlice({
    name:"event",
    initialState:{
        popup:{
            holiday:false,
            event:false
        },
        form:{
            holidayForm:{
                date:Moment(new Date()).format("YYYY-MM-DD"),
                date_timing:Moment(new Date()).format("YYYY-MM-DD hh:mm A"),
                timing:"",
                title:"",
                description:"",
                icon:"",
                reminder_stream:""
            },
            eventForm:{
                date:Moment(new Date()).format("YYYY-MM-DD"),
                start_timing:Moment(new Date()).format("YYYY-MM-DD hh:mm A"),
                end_timing:Moment(new Date()).format("YYYY-MM-DD hh:mm A"),
                title:"",
                description:"",
                icon:"",
                reminder_stream:"",
                invitees:"",
                teacher:true,
                parent:false,
                classes:[]
            }
        }
    },
    reducers:{
       openHoliday:(state)=>{
        state.popup.holiday=true
       },
       closeHoliday:(state)=>{
        state.popup.holiday=false
       },
       openEvent:(state)=>{
        state.popup.event=true
       },
       closeEvent:(state)=>{
        state.popup.event=false
       },

       createHolidayForm:(state, action)=>{
        state.form.holidayForm=action.payload
       },
       createEventForm:(state, action)=>{
        state.form.eventForm=action.payload
       },
    }
})

export const {openEvent, closeEvent, createHolidayForm,
createEventForm, closeHoliday, openHoliday}=event.actions

export default event.reducer

export const getEvent=state=>state.event.popup.event
export const getHoliday=state=>state.event.popup.holiday
export const getHolidayForm=state=>state.event.form.holidayForm
export const getEventForm=state=>state.event.form.eventForm

