import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const boardcast = createSlice({
    name:"boardcast",
    initialState:{
        popup:{
            remindAboutEvent:false,
            createPoll:false,
            information:false,
            feesReminder:false
        },
        form:{
            remindAboutEventForm:{
                event_title:"",
                event_description:"",
                event_icon:"",
                classes:[],
                teacher:false,
                parent:false
            },
            createPollForm:{
                poll_question:"",
                description:"",
                choices:[],
                last_voting_date:moment(new Date()).format("YYYY-MM-DD"),
                end_time:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                classes:[],
                teacher:false,
                parent:false
            },
            feesReminderForm:{
                fees_component:"",
                reminder_message:"",
                last_date:moment(new Date()).format("YYYY-MM-DD"),
                end_time:moment(new Date()).format("YYYY-MM-DD hh:mm A"),
                classes:[],
                teacher:false,
                parent:false            
            }
        }
    },
    reducers:{

        // open and close popup action
       openRemindAboutEvent:(state)=>{
        state.popup.remindAboutEvent=true
       },
       closeRemindAboutEvent:(state)=>{
        state.popup.remindAboutEvent=false
       },
       openCreatePoll:(state)=>{
        state.popup.createPoll=true
       },
       closeCreatePoll:(state)=>{
        state.popup.createPoll=false
       },
       openInformation:(state)=>{
        state.popup.information=true
       },
       closeInformation:(state)=>{
        state.popup.information=false
       },
       openFeesReminder:(state)=>{
        state.popup.feesReminder=true
       },
       closeFeesReminder:(state)=>{
        state.popup.feesReminder=false
       },

       // form action
       addRemindAboutEventForm:(state, action)=>{
        state.form.remindAboutEventForm=action.payload
       },
       addCreatePollForm:(state, action)=>{
        state.form.createPollForm=action.payload
       },
       addFeesReminderForm:(state, action)=>{
        state.form.feesReminderForm=action.payload
       },
    }
})

export const {openRemindAboutEvent, openCreatePoll, closeRemindAboutEvent,
closeCreatePoll, openInformation, closeInformation, 
closeFeesReminder, openFeesReminder, addCreatePollForm,
addFeesReminderForm, addRemindAboutEventForm}=boardcast.actions

export default boardcast.reducer

// popup state
export const getRemindAboutEvent=state=>state.boardcast.popup.remindAboutEvent
export const getCreatePoll=state=>state.boardcast.popup.createPoll
export const getInformation=state=>state.boardcast.popup.information
export const getFeesReminder=state=>state.boardcast.popup.feesReminder

// form state
export const getRemindAboutEventForm=state=>state.boardcast.form.remindAboutEventForm
export const getCreatePollForm=state=>state.boardcast.form.createPollForm
export const getFeesReminderForm=state=>state.boardcast.form.feesReminderForm

