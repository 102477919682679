import { createSlice } from "@reduxjs/toolkit";

const navigationId= sessionStorage.getItem('navigationId') !== null ? 
JSON.parse(sessionStorage.getItem('navigationId')) : null

const sidebarSlice = createSlice({
    name:"sidebar",
    initialState:{
        navigationId:navigationId,
        value:false,
        calender:false,
        broadcast:false,
        notification:false,
        leftbar:false,
        logout:false,
        fund:false,
        form:{
            eventForm:{
                date:"",
                icon:"",
                title:"",
                description:"",
                start_time:"",
                end_time:"",
                type:"",
                timing:true,
                notification:false
            },
            broadcastForm:{
                date:"",
                event_icon:"",
                event_title:"",
                event_description:"",
                describe_poll:"",
                information_title:"",
                information_description:"",
                reminder_title:"",
                reminder_description:"",
                reminder_icon:"",
                holiday_icon:"",
                holiday_title:"",
                holiday_description:"",
                receiver:"",
                options:[],
                class:[],
                submission_date:'',
                type:"Event",
            },
        }
    },
    reducers:{
        addNavigationId:(state, action)=>{
            state.navigationId=action.payload
            sessionStorage.setItem('navigationId', 
            JSON.stringify(state.navigationId))
        },

        //sidebar popup
        openSidebar:state=>{
            state.value=true
        },
        closeSidebar:state=>{
            state.value=false
        },
        openCalender:state=>{
            state.calender=true
        },
        closeCalender:state=>{
            state.calender=false
        },
        openBroadcast:state=>{
            state.broadcast=true
        },
        closeBroadcast:state=>{
            state.broadcast=false
        },
        openNotification:state=>{
            state.notification=true
        },
        closeNotification:state=>{
            state.notification=false
        },
        openLeftbar:state=>{
            state.leftbar=true
        },
        closeLeftbar:state=>{
            state.leftbar=false
        },
        openLogOut:state=>{
            state.logout=true
        },
        closeLogOut:state=>{
            state.logout=false
        },
        openFund:state=>{
            state.fund=true
        },
        closeFund:state=>{
            state.fund=false
        },

        //side bar form
        addEventForm:(state, action)=>{
            state.form.eventForm=action.payload
        },
        addBoardcastForm:(state, action)=>{
            state.form.broadcastForm=action.payload
        }
    }
})

export const {addNavigationId, openSidebar, closeSidebar, openBroadcast, 
    openCalender, closeBroadcast, closeCalender, openNotification, 
closeNotification, openLeftbar, closeLeftbar, openLogOut,
closeLogOut, openFund, closeFund, addEventForm, addBoardcastForm}=sidebarSlice.actions

export const getNavigationId=(state)=>state.sidebar.navigationId

//side bar popup state
export const getSidebar=(state)=>state.sidebar.value
export const getCalender=(state)=>state.sidebar.calender
export const getBroadcast=(state)=>state.sidebar.broadcast
export const getNotification=(state)=>state.sidebar.notification
export const getLeftbar=(state)=>state.sidebar.leftbar
export const getLogOut=(state)=>state.sidebar.logout
export const getFund=(state)=>state.sidebar.fund

//side bar form state
export const getEventForm=(state)=>state.sidebar.form.eventForm
export const getBoardcastForm=(state)=>state.sidebar.form.broadcastForm

export default sidebarSlice.reducer

