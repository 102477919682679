// react library imports
import { lazy } from 'react';
import { createBrowserRouter} from 'react-router-dom';

// Layout imports
const PublicLayout = lazy(()=>import('Layout/Public'))
const DashboardLayout = lazy(()=>import('Layout/Dashboard'))
const PrincipalLayout = lazy(()=>import('Layout/Principal'))
const RegisterLayout = lazy(()=>import('Layout/Register'))
const PoliciesLayout = lazy(()=>import('Layout/Policies'))

// public pages imports
const LandingPage = lazy(()=>import('Pages/Public/LandingPage/LandingPage'))
const About = lazy(()=>import('Pages/Public/About/About'))
const Philosophy = lazy(()=>import('Pages/Public/Philosophy/Philosophy'))
const Careers = lazy(()=>import('Pages/Public/Careers/Careers'))
const Preschool = lazy(()=>import('Pages/Public/Preschool/Preschool'))
const TeacherPage = lazy(()=>import('Pages/Public/Teacher/Teacher'))
const Parents = lazy(()=>import('Pages/Public/Parents/Parents'))
const Resource = lazy(()=>import('Pages/Public/Resource/Resource'))

// private pages imports 
const Home = lazy(()=>import('Pages/Private/Home/Home'))
const Team = lazy(()=>import('Pages/Private/Team/Team'))
const Teacher = lazy(()=>import('Pages/Private/Team/Teacher/Teacher'))
const Admin = lazy(()=>import('Pages/Private/Team/Admin/Admin'))
const HelpingStaff = lazy(()=>import('Pages/Private/Team/HelpingStaff/HelpingStaff'))
const LeaderBoard = lazy(()=>import('Pages/Private/Team/LeaderBoard/LeaderBoard'))
const Classes = lazy(()=>import('Pages/Private/Classes/Classes'))
const Curriculum = lazy(()=>import('Pages/Private/Curriculum/Curriculum'))
const Elg = lazy(()=>import('Pages/Private/Curriculum/Elg/Elg'))
const AnnualPlanner = lazy(()=>import('Pages/Private/Curriculum/AnnualPlanner/AnnualPlanner'))
const HolisticDistribution = lazy(()=>import('Pages/Private/Curriculum/HolisticDistribution/HolisticDistribution'))
const Planner = lazy(()=>import('Pages/Private/Curriculum/Planner/Planner'))
const Inventory = lazy(()=>import('Pages/Private/Inventory/Inventory'))
const AddInventory = lazy(()=>import('Pages/Private/Inventory/AddInventory/AddInventory'))
const Allocation = lazy(()=>import('Pages/Private/Inventory/Allocation/Allocation'))
const InventoryOverview = lazy(()=>import('Pages/Private/Inventory/InventoryOverview/InventoryOverview'))
const Finances = lazy(()=>import('Pages/Private/Finances/Finances'))
const AddTransaction = lazy(()=>import('Pages/Private/Finances/AddTransaction/AddTransaction'))
const Analysis = lazy(()=>import('Pages/Private/Finances/Analysis/Analysis'))
const FeesOverview = lazy(()=>import('Pages/Private/Finances/FeesOverview/FeesOverview'))
const Security = lazy(()=>import('Pages/Private/Security/Security'))
const Marketing = lazy(()=>import('Pages/Private/Marketing/Marketing'))
const Event = lazy(()=>import('Pages/Private/Event/Event'))
const Broadcast = lazy(()=>import('Pages/Private/Broadcast/Broadcast'))
const ChatPage = lazy(()=>import('Pages/Private/Chat/Chat'))
const Notification =lazy(()=>import('Pages/Private/Notification/Notification'))
const Setting =lazy(()=>import('Pages/Private/Setting/Setting'))

// principal pages imports
const Principal = lazy(()=>import('Pages/PreschoolList/PreschoolList'))

// register pages
const PreschoolRegistration = lazy(()=>import('Pages/Register/PreschoolRegistration/PreschoolRegistration'))
const UploadLogo = lazy(()=>import('Pages/Register/UploadLogo/UploadLogo'))
const WelcomeScreen = lazy(()=>import('Pages/Register/WelcomeScreen/WelcomeScreen'))
const FundCredited = lazy(()=>import('Pages/Register/FundCredited/FundCredited'))

// Error pages
const NotFound = lazy(()=>import('Pages/404NotFound/404NotFound'))

// policies pages
const Academy = lazy(()=>import('Pages/PrivacyPolicy/Academy/Academy'))
const Parent = lazy(()=>import('Pages/PrivacyPolicy/Parent/Parent'))
const TeacherPolicies = lazy(()=>import('Pages/PrivacyPolicy/Teacher/Teacher'))
const PlayAR = lazy(()=>import('Pages/PrivacyPolicy/PlayAR/PlayAR'))
const AccountDelete = lazy(()=>import('Pages/PrivacyPolicy/Academy/AccountDelete'))

export const router = createBrowserRouter([
    {
        path:"/",
        element:<PublicLayout/>,
        children:[
            {
                index:true,
                element:<LandingPage/>
            },
            {
                path:"about",
                element:<About/>
            },
            {
                path:"philosophy",
                element:<Philosophy/>
            },
            {
                path:"career",
                element:<Careers/>
            },
            {
                path:"teacher",
                element:<TeacherPage/>
            },
            {
                path:"parent",
                element:<Parents/>
            },
            {
                path:"resource",
                element:<Resource/>
            },
            {
                path:"preschool",
                element:<Preschool/>
            },
        ]
    },
    {
        path:"dashboard/",
        element:<DashboardLayout/>,
        children:[
            {
                index:true,
                element:<Home/>
            },
            {
                path:"team/",
                element:<Team/>,
                children:[
                    {
                        path:'teacher',
                        element:<Teacher/>
                    },
                    {
                        path:'admin',
                        element:<Admin/>
                    },
                    {
                        path:'helping-staff',
                        element:<HelpingStaff/>
                    },
                    {
                        path:'leader-board',
                        element:<LeaderBoard/>
                    },
                ]
            },
            {
                path:"classes/:id",
                element:<Classes/>
            },
            {
                path:"classes",
                element:<Classes/>
            },
            {
                path:"curriculum/",
                element:<Curriculum/>,
                children:[
                    {
                        path:"elg",
                        element:<Elg/>
                    },
                    {
                        path:"annual-planner",
                        element:<AnnualPlanner/>
                    },
                    {
                        path:"planner",
                        element:<Planner/>
                    },
                    {
                        path:"holistic-distribution",
                        element:<HolisticDistribution/>
                    },
                ]
            },
            {
                path:"inventory/",
                element:<Inventory/>,
                children:[
                    {
                        path:"inventory-overview",
                        element:<InventoryOverview/>
                    },
                    {
                        path:"allocation",
                        element:<Allocation/>
                    },
                    {
                        path:"add-inventory",
                        element:<AddInventory/>
                    },
                ]
            },
            {
                path:"finances",
                element:<Finances/>,
                children:[
                    {
                        path:"fees-overview",
                        element:<FeesOverview/>
                    },
                    {
                        path:"analysis",
                        element:<Analysis/>
                    },
                    {
                        path:"add-transaction",
                        element:<AddTransaction/>
                    },
                ]
            },
            {
                path:"security",
                element:<Security/>
            },
            {
                path:"marketing",
                element:<Marketing/>
            },
            {
                path:"event",
                element:<Event/>
            },
            {
                path:"broadcast",
                element:<Broadcast/>
            },
            {
                path:"chat",
                element:<ChatPage/>
            },
            {
                path:"notification",
                element:<Notification/>
            },
            {
                path:"setting",
                element:<Setting/>
            },
        ]
    },
    {
        path:'preschools',
        element:<PrincipalLayout/>,
        children:[
            {
                index:true,
                element:<Principal/>
            }
        ]
    },
    {
        path:"preschool-registration/",
        element:<RegisterLayout/>,
        children:[
            {
                index:true,
                element:<PreschoolRegistration/>
            },
            {
                path:"welcome-screen",
                element:<WelcomeScreen/>
            },
            {
                path:"upload-logo",
                element:<UploadLogo/>
            },
            {
                path:"fund-credited",
                element:<FundCredited/>
            },
        ]
    },
    {
        path:"privacy-policy",
        element:<PoliciesLayout/>,
        children:[
            {
                path:'mues-parent',
                element:<Parent/>
            },
            {
                path:"mues-teacher",
                element:<TeacherPolicies/>
            },
            {
                path:"mues-playar",
                element:<PlayAR/>
            },
            {
                path:"mues-academy",
                element:<Academy/>
            },
            {
                path:"delete-account",
                element:<AccountDelete/>
            },
        ]
    },
    {
        path:"*",
        element:<NotFound/>
    }
])
