import { createSlice } from "@reduxjs/toolkit";

const chat = createSlice({
    name:"chat",
    initialState:{
        latestMessage:null,
        chatStaffList:[],
        chatDetails:null,
        activeChat:null
    },
    reducers:{
       addLatestMessage:(state, action)=>{
            state.latestMessage=action.payload
            const  message={
                message:action.payload?.message,
                sender:action.payload?.send,
                time:action.payload?.time,
                id:action.payload?.send,
                status:state.activeChat?.individual_chat_group_id===action.payload?.individual_group_id ? true:false
            }
            const index = state.chatStaffList.findIndex(item => item.individual_chat_group_id === action.payload?.individual_group_id);
            if (index !== -1) {
                const item = state.chatStaffList[index];
                item.message = message;
                state.chatStaffList.splice(index, 1);
                state.chatStaffList.unshift(item);
            }
            if (state.activeChat?.individual_chat_group_id===action.payload?.individual_group_id) {
                state.chatDetails?.push(message)
            }
       },
       addStaffList:(state, action)=>{
        state.chatStaffList=action.payload
       },
       addChatStaffDetails:(state, action)=>{
        state.chatDetails=action.payload
       },
       addActiveChat:(state, action)=>{
        state.activeChat=action.payload
       }
    }
})

export const {addLatestMessage, addChatStaffDetails, 
    addStaffList, addActiveChat}=chat.actions

export default chat.reducer

// get chat details
export const getLatestMessage=(state)=>state.chat.latestMessage
export const getChatStaffList=(state)=>state.chat.chatStaffList
export const getChatDetails=(state)=>state.chat.chatDetails
export const getActiveChat=(state)=>state.chat.activeChat

